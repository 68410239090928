<template>
  <div>
    <div
      v-for="(order, index) of data"
      :key="`order-${index}`"
      size="A4"
      class="mt-14 order-summary break-page">
      <div class="d-flex py-2">
        <div class="w-haft">
          <img
            width="100"
            alt="gw"
            src="@/assets/image/GTWM_Main_Logo-02.png">
          <p class="mt-6">
            บริษัท เจนเทิลวูแมน จำกัด (สำนักงานใหญ่) <br>
            999/9 อาคารดิออฟฟิศเศส แอท เซ็นทรัลเวิลด์ <br>
            ห้องเลขที่ OFMH 3806-3812 ชั้นที่ 38 ถนนพระราม 1 <br>
            แขวงปทุมวัน เขตปทุมวัน กรุงเทพมหานคร 10330 <br>
            เลขประจำตัวผู้เสีย 0-1055-61034-56-1
          </p>
          <div class="pt-1 pr-8">
            <div class="text-bold">
              ข้อมูลลูกค้า
            </div>
            <p class="">
              {{ order.shippingAddress.firstName }} {{ order.shippingAddress.lastName }} <br>
              {{ order.shippingAddress.address || '-' }}
              {{ order.shippingAddress.address2 }}
              <span v-if="order.shippingAddress.subDistrict">แขวง/ตำบล</span> {{ order.shippingAddress.subDistrict }}
              <span v-if="order.shippingAddress.district">เขต/อำเภอ</span> {{ order.shippingAddress.district }}
              {{ order.shippingAddress.province }}
              <span v-if="order.shippingAddress.country">ประเทศ</span>
              {{ order.shippingAddress.country }} {{ order.shippingAddress.postcode }}
            </p>
          </div>
        </div>
        <div class="w-haft text-left">
          <p class="order-summary-text">
            ORDER SUMMARY
          </p>
          <tr>
            <th class="px-4 pt-1">
              เลขที่
            </th>
            <td class="px-4 pt-1">
              {{ order.orderId }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              วันที่ทำรายการ
            </th>
            <td class="px-4 pt-1">
              {{ order.createdAt | dateTimeFormat() }}
            </td>
          </tr>
          <tr>
            <th class="px-4 pt-1">
              สินค้าทั้งหมด
            </th>
            <td class="px-4 pt-1">
              {{ amountItem(order) }} รายการ
            </td>
          </tr>
        </div>
      </div>
      <div class="mt-5 product-table">
        <table style="width: 100%">
          <tr>
            <th class="px-4 py-4 table-head">
              รูป
            </th>
            <th class="px-4 py-4 table-head">
              รายการสินค้า
            </th>
            <th class="px-4 py-4 table-head">
              จำนวน
            </th>
            <th class="px-4 py-4 table-head text-right">
              ราคา
            </th>
          </tr>
          <tr
            v-for="(sku, ind) of order.skus"
            :key="`${index}-${ind}`">
            <td class="px-4 py-1 text-center">
              <img
                :src="imgSrcCompute(sku.images[0])"
                width="40px"
                alt="">
            </td>
            <td class="px-4 py-1">
              {{ sku.name }} ({{ sku.size }} - {{ sku.color }})
            </td>
            <td class="px-4 py-1 text-center">
              {{ sku.amount }}
            </td>
            <td class="px-4 py-1 text-right">
              {{ sku.price | showFullPriceFormat() }}
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-10 d-flex justify-space-between align-end">
        <div class="pb-2">
          <img
            width="220"
            alt="gw"
            src="@/assets/image/GTWM_Main_Logo-01.png">
        </div>
        <div class="summary">
          <div class="d-flex justify-space-between">
            <span class="text-bold">
              ค่าจัดส่ง
            </span>
            <span>
              {{ order.shipping.fee | showFullPriceFormat() }}
            </span>
          </div>

          <div class="d-flex justify-space-between">
            <span class="text-bold">
              ส่วนลด
            </span>
            <span>
              {{ order.discount | showFullPriceFormat() }}
            </span>
          </div>
          <div class="mt-4 d-flex justify-space-between">
            <span class="text-bold">
              ยอดรวมสุทธิ
            </span>
            <span>
              {{ order.net | showFullPriceFormat() }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderProvider from '@/resources/OrderProvider'

const OrderService = new OrderProvider()

export default {
  data () {
    return {
      fetching: false,
      data: [],
      orderIds: []
    }
  },
  computed: {
    orderId () {
      return 'GW2022101700125'
    }
  },
  async mounted () {
    this.orderIds = JSON.parse(window.localStorage.getItem('print-order-summay'))
    await this.fetchOrder()
    window.scrollTo(0, document.body.scrollHeight)
    setTimeout(() => {
      window.print()
    }, 1500)
  },
  methods: {
    amountItem (data) {
      if (!data) return 0
      return data.skus.reduce((sum, sku) => sum + sku.amount, 0)
    },
    imgSrcCompute (src) {
      return src || 'https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png'
    },
    async fetchOrder () {
      try {
        this.fetching = true
        const { data } = await OrderService.getOrderMany({
          orderIds: this.orderIds
        })
        this.data = data.reverse()
      } catch (error) {
        this.$store.dispatch('Components/setSnackbar', {
          value: true,
          message: `${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetching = false
      }
    }
  }
}
</script>

<style>
  .break-page {
    page-break-after: always;
    page-break-before: always;
    display: none;
    clear: both;
  }

  .product-table {
    min-height: 565px;
  }
  .summary {
    padding: 12px;
    width: 300px;
    background-color: #f6f6f6;
  }
  .table-head {
    background-color: #f6f6f6;
    border-bottom: 1px solid #000;
  }
  .order-summary {
    background: #fff;
    width: 210mm;
    min-height: 297mm;
    display: block;
    margin: 40px auto;
    padding: 14mm 15mm;
    font-size: 12px;
    box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
    color: #000;
  }
  .w-haft {
    width: 50%;
  }
  .order-summary-text {
    font-size: 28px;
    font-weight: 400;
    line-height: 1;
    text-align: right;
  }
</style>
